import React from 'react';
import ServiceCard from 'components/Common/ServiceCard/ServiceCard';
import './style.css';
import { toAbsoluteUrl } from 'assets/helpers/AssestHelpers';
const Service = () => {
	const cardData = [
		{
			img: toAbsoluteUrl('/media/images/Service/service-1.svg'),
			title: 'Onboarding',
			text: 'At Titiksha Wealth, our journey with you begins with a seamless onboarding process. We ensure all necessary steps are taken to make you comfortable and well-informed.',
		},
		{
			img: toAbsoluteUrl('/media/images/Service/service-2.svg'),
			title: 'KYC',
			text: 'As a critical step, we complete the Know Your Customer (KYC) process. This ensures compliance and helps us understand your needs better. Once completed, we provide ongoing advice via email and WhatsApp.',
		},
		{
			img: toAbsoluteUrl('/media/images/Service/service-3.svg'),
			title: 'Investment',
			text: 'You retain full control over your transactions. We offer guidance, and you make trades. We also provide regular stock rebalancing advice in the same manner, ensuring your portfolio remains optimized.',
		},
	];
	return (
		<>
			<section id="serviceSection">
				<div className="container">
					<div className="row">
						<div className="col py-lg-5">
							<div className="row">
								<div className="col py-lg-5">
									<div className="px-1 px-lg-0">
										<div className="pt-5 text-center colorWhite">
											<div className="fs-16 mr_reg color6 mb-lg-0 mb-2">Why Titiksha?</div>
											<div className="fs-48 pb-5 rw_reg color3 service-title">
												A Different Approach to Wealth
											</div>
										</div>
										<div className=" row  mx-lg-0 justify-content-lg-between justify-content-md-between gap-5 gap-lg-0 gap-md-0">
											{cardData.map((card, index) => (
												<ServiceCard
													key={index}
													img={card.img}
													title={card.title}
													text={card.text}
												/>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Service;
